// -----------------------------------------------------------------------------------
// Funktion prüft, ob eine Anmeldung aufgrund bestimmter Status im Email-Status

import {
    WORKFLOW__STATUS,
    WORKSHOP__STATUS,
    mapWorkshopStatus,
} from '../../backend-id-constants';

// und im Workshop-Status durch die Schule noch editiert werden darf
export default function isWorkflowEditable(anmeldung) {
    return (
        anmeldung.idStatusWorkflow <
            WORKFLOW__STATUS.FORMBLATT_VERSCHICKT.value &&
        [
            WORKSHOP__STATUS.NONE,
            WORKSHOP__STATUS.OFFEN,
            WORKSHOP__STATUS.IN_KLAERUNG,
        ].includes(mapWorkshopStatus(anmeldung.idStatusWorkshop))
    );
}
