import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Loader from '../../common/loader';
import Question from '../../common/question';
import {
    BACKEND_URL,
    PARTICIPANT_ADD,
    PARTICIPANT_SAVE,
} from '../../../global_constants';
import {
    TEILNEHMER__ZUSCHUSS__4EUR,
    TEILNEHMER__ZUSCHUSS__GVH,
    TEILNEHMER__ZUSCHUSS__KEIN,
    TEILNEHMER__ANREDE__FRAU,
    TEILNEHMER__ANREDE__HERR,
    TEILNEHMER__ANREDE__KEINE,
    TEILNEHMER__KATEGORIE__SCHULANMELDUNG,
    TEILNEHMER__TYP__GRUPPE,
    WORKFLOW__STATUS,
    WORKSHOP__STATUS,
} from '../../../backend-id-constants';
import { getCustomLst } from '../../../actions/common/actions.js';

import {
    setGroupRegistrationUpdate,
    setEditedAnmeldung,
} from '../../../actions/registration-system/registration-actions.js';

import style from '../../common/index.module.css';

import createAnmeldecode from '../createAnmeldecode.js';

class Send extends Question {
    constructor(props) {
        super(
            props,
            [
                // "form.school_selection.values.school",
                'form.registration_personals.values',
                'form.group.values',
                'form.schedule.values',
                'form.allowance.values',
            ],
            {
                invalid: false,
            },
        );
    }
    validate() {
        this.send();
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get allowanceList, when it's not in Redux yet
                if (
                    this.props.allowanceList &&
                    !Object.keys(this.props.allowanceList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('allowance'))
                        .then(null, (_e) =>
                            Promise.reject('allowance not available'),
                        );
                }
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    send() {
        const salutationMap = {
            Herr: TEILNEHMER__ANREDE__HERR,
            Frau: TEILNEHMER__ANREDE__FRAU,
            KeineAngabe: TEILNEHMER__ANREDE__KEINE,
        };

        const formdata = this.props.formdata;
        const school = this.props.schoolList.find(
            (school) =>
                school.ID.toString() ===
                formdata.school_selection.values.school_selection,
        );
        const isOepnv = school.kzOepnv;

        const personals = formdata.registration_personals.values;
        const isEditedAnmeldung = this.props.editedAnmeldung.id > -1;
        const anmeldecode = isEditedAnmeldung
            ? this.props.editedAnmeldung.anmeldecode
            : createAnmeldecode(personals.email);
        const endpoint = isEditedAnmeldung ? PARTICIPANT_SAVE : PARTICIPANT_ADD;
        const editedAnmeldungDataOld = isEditedAnmeldung
            ? this.props.groupRegistrationList[this.props.editedAnmeldung.index]
            : {};

        let idZuschuss =
            (this.props.allowanceList || []).find(
                (item) => item.externId === 'kein',
            )?.ID || TEILNEHMER__ZUSCHUSS__KEIN;

        if (formdata.allowance.values.allowance === 'yes') {
            if (isOepnv === 0) {
                idZuschuss =
                    (this.props.allowanceList || []).find(
                        (item) => item.externId === '4EUR',
                    )?.ID || TEILNEHMER__ZUSCHUSS__4EUR;
            } else if (isOepnv === 1) {
                idZuschuss =
                    (this.props.allowanceList || []).find(
                        (item) => item.externId === 'GVH',
                    )?.ID || TEILNEHMER__ZUSCHUSS__GVH;
            }
        }

        const jsonNew = JSON.stringify({
            ...editedAnmeldungDataOld,
            login: {
                // TODO: pick properties manually!
                ...this.props.authenticationData.login,
                userId4Hist: undefined,
            },
            idAbfahrtszeit: 0,
            idAbfahrtzeitSchule: formdata.schedule.values.departureTime || -1,
            idAnkunftszeit: formdata.schedule.values.arrivalTime || -1,
            idKategorie: TEILNEHMER__KATEGORIE__SCHULANMELDUNG,
            idKlasseStufe: formdata.group.values.classLevel || -1,
            idLand: 0,
            idStatusWorkflow: isEditedAnmeldung
                ? editedAnmeldungDataOld.idStatusWorkflow
                : WORKFLOW__STATUS.MAIL_GESENDET.value,
            idStatusWorkshop: isEditedAnmeldung
                ? editedAnmeldungDataOld.idStatusWorkshop
                : WORKSHOP__STATUS.OFFEN.value,
            idTyp: TEILNEHMER__TYP__GRUPPE,
            idUser: 0,
            idVerkehrsmittel: formdata.schedule.values.transport || -1,
            idZuschuss: idZuschuss,
            kzSchuleGVH: isOepnv,

            buchungNr: anmeldecode,

            // TODO: add fields requiring Id's because of relations to other entities
            idProjekt: this.props.authenticationData.TpProjektLstItem[0].ID,

            idSchule: formdata.school_selection.values.school_selection,
            idSchulZweig: formdata.group.values.schoolTrack,

            idAnrede: salutationMap[personals.salutation],
            vorname: personals.firstname,
            name: personals.lastname,

            PLZ: formdata.school_selection.values.zipcode,
            bundesland: formdata.school_selection.values.federalState,
            strasse: school.address,
            ort: school.city,

            EMail: personals.emailSchool,
            emailPrivat: personals.email,
            telefon: personals.phoneSchool,
            telefonPrivat: personals.phone,

            gruppeBezeichnung: formdata.group.values.name,
            anzahlMaedchen: formdata.group.values.girls,
            anzahlJungen: formdata.group.values.boys,
            anzahlBegleiter: formdata.group.values.leaders,

            anreisetag: formdata.schedule.values.arrivalDay, // TODO: von String zu Datum umwandeln / speichern ?

            kzWorkshop: formdata.schedule.values?.workshop ? 1 : 0,
            kzFuehrung: formdata.schedule.values?.guidance ? 1 : 0,
            kzClubZukunft: formdata.schedule.values?.clubZukunft ? 1 : 0,
            kzNewsletter: formdata.validate.values?.newsletter ? 1 : 0,
            //kzSchuleGVH
            ...(formdata.allowance.values.allowance === 'yes'
                ? {
                      kontoinhaber: formdata.allowance.values.accountHolder,
                      IBAN: formdata.allowance.values.IBAN,
                      verwendungszweck:
                          formdata.allowance.values.paymentReference,
                  }
                : {}),
        });

        fetch(BACKEND_URL + endpoint, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.props.authenticationData.login.accessToken}`,
            },
            body: jsonNew,
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.statusText);
                } else {
                    return response.text();
                }
            })
            .then((text) => {
                try {
                    return JSON.parse(text);
                } catch (e) {
                    return Promise.reject(e);
                }
            })
            .then((json) => {
                this.props.dispatch(setGroupRegistrationUpdate(json));
                return json;
            })
            .then((json) => {
                // TODO: robustness: validate if participant saved correctly
                console.info('sent participant', json);
                return json;
            })
            .then((participant) => {
                this.props.dispatch(
                    setEditedAnmeldung(-1, -1, participant.buchungNr),
                );
                this.props.dispatch(push('/success'));
            })
            .catch((e) => {
                console.error(
                    'error sending participant, promise rejected:',
                    e,
                );
                this.setState({
                    invalid: true,
                });
            });
    }

    render() {
        if (!this.state.invalid) {
            return (
                <div className={style.questionContainer}>
                    <div className={style.question}>
                        <Loader visible={true} />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={style.questionContainer}>
                    <div className={style.question}>
                        <h1>
                            <strong>Fehler</strong> beim Absenden
                        </h1>
                        <p>
                            Es gab einen Fehler beim Speichern der Daten. Bitte
                            laden Sie die Seite neu und versuchen es erneut.
                        </p>
                    </div>
                </div>
            );
        }
    }
}

export default connect((s) => ({ formdata: s.form, ...s }))(Send);
