import React from 'react';
import StepButtons from '../../common/stepbuttons';
import style from '../../common/index.module.css';

export default () => (
    <div className={style.question}>
        <h1>
            <b>Ihre Anfrage</b> an uns wurde abgeschickt.
        </h1>
        <p>
            Wir werden die Schuldaten umgehend eingeben und Sie informieren,
            sodass Sie Ihre Anmeldung vornehmen können.
        </p>
        <StepButtons back='/welcome' />
    </div>
);
