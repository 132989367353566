import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, destroy } from 'redux-form';
import classNames from 'classnames';
import dayjs from 'dayjs';

import StepButtons from '../../common/stepbuttons';
import commonstyle from '../../common/index.module.css';
import style from './style.module.css';

import Question from '../../common/question';
import Loader from '../../common/loader';

import { getCustomLst } from '../../../actions/common/actions.js';

import { showAlert, ERROR, ERROR_ANMELDECODE } from '../../exhibitor/alerts';

import { ZIPCODE_PATTERN } from '../../../global_constants';
import {
    fetchSchoolList,
    fetchGroupRegistrationList,
    setArrivalDayList,
    fetchZipcodeMapping,
    setEditedAnmeldung,
    setGroupRegistrationList,
    setSchoolList,
} from '../../../actions/registration-system/registration-actions';
import {
    WORKFLOW__STATUS,
    WORKSHOP__STATUS,
} from '../../../backend-id-constants.js';

class SchoolSelection extends Question {
    constructor(props) {
        super(props, null, {
            valid: false,
            fetchingSchoolList: 0,
            fetchingRegistrationList: 0,
        });

        this.clearValues = this.clearValues.bind(this);
    }

    UNSAFE_componentWillMount() {
        Promise.resolve()
            .then(() => {
                // get arrivaltime list, when it's not in Redux yet
                if (
                    this.props.arrivalTimeList &&
                    !Object.keys(this.props.arrivalTimeList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('arrivaltime'))
                        .then(null, (_e) =>
                            Promise.reject('arrivaltimes not available'),
                        );
                }
            })
            .then(() => {
                // get transportList, when it's not in Redux yet
                if (
                    this.props.transportList &&
                    !Object.keys(this.props.transportList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('transport'))
                        .then(null, (_e) =>
                            Promise.reject('transport not available'),
                        );
                }
            })
            .then(() => {
                // get departureTimeSchoolList, when it's not in Redux yet
                if (
                    this.props.departureTimeSchoolList &&
                    !Object.keys(this.props.departureTimeSchoolList).length
                ) {
                    return this.props
                        .dispatch(getCustomLst('departureschool'))
                        .then(null, (_e) =>
                            Promise.reject('transport not available'),
                        );
                }
            })
            .then(() => {
                const projekt = (this.props.authenticationData
                    ?.TpProjektLstItem || [{}])[0];
                if (projekt) {
                    this.props.dispatch(
                        setArrivalDayList(projekt.beginn, projekt.ende),
                    );
                }
            })
            .catch((e) => {
                console.error('error checking code, promise rejected:', e);
                this.setState({
                    state: e?.state ? e.state : 'invalid',
                });
            });
    }

    componentDidMount() {
        this.props.dispatch(setEditedAnmeldung(-1, -1, ''));
        this.updateFederalState(
            this.props.formdata.school_selection.values.zipcode,
        );
        super.componentDidMount();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.plzMapping !== this.props.plzMapping) {
            this.props.change(
                'federalState',
                this.props.plzMapping?.Bundesland || '(error)',
            );
        }
    }

    clearValues() {
        this.props.change('school_selection', '');
        this.props.dispatch(setGroupRegistrationList([]));
        this.props.dispatch(setSchoolList([]));
    }

    validate() {
        setTimeout(() => {
            const schoolID =
                this.props.formdata.school_selection.values.school_selection;
            console.log(`Schul_ID: ${schoolID}`);
            // TODO: real validation
            if (this.props.schoolList.length === 1) {
                console.log('single school');
                // this.props.change('school_selection', this.props.schoolList[0].ID)
                this.setState({
                    valid: true,
                });
            } else {
                console.log('multiple schools');
                this.setState({
                    valid: !!schoolID,
                });
            }

            if (schoolID && this.props.schoolList) {
                console.log('clear timeout');
                clearTimeout(this.state.fetchingRegistrationList);
                this.setState({
                    fetchingRegistrationList: setTimeout(() => {
                        const schoolID =
                            this.props.formdata.school_selection.values
                                .school_selection;
                        this.props
                            .dispatch(
                                fetchGroupRegistrationList(
                                    this.props.authenticationData,
                                    schoolID,
                                ),
                            )
                            .then(() => {
                                console.log('fetched!');
                                console.log(
                                    `fetchingRegistrationList: ${this.state.fetchingRegistrationList}`,
                                );
                                this.setState({ fetchingRegistrationList: 0 });
                            });
                    }, 250),
                });
            }
        }, 0);
    }
    validateZipcode = (value) => {
        const valid = value && ZIPCODE_PATTERN.test(value) ? value : undefined;
        this.setState({ validZipcode: !!valid });
        if (!valid) {
            this.clearValues();
        }
        return valid;
    };
    updateSchoolNumber(schoolNumber) {
        clearTimeout(this.state.fetchingSchoolList);
        this.setState({
            fetchingSchoolList: setTimeout(() => {
                return this.props
                    .dispatch(
                        fetchSchoolList(
                            this.props.formdata.school_selection.values.zipcode,
                            schoolNumber,
                        ),
                    )
                    .then(() => {
                        this.validate();
                        this.setState({ fetchingSchoolList: 0 });
                    });
            }, 250),
        });
    }
    updateZipcode(zipcode) {
        this.clearValues();
        if (!zipcode || zipcode.length < 5) {
            return;
        }

        this.updateFederalState(zipcode);
        clearTimeout(this.state.fetchingSchoolList);
        this.setState({
            fetchingSchoolList: setTimeout(() => {
                this.props.dispatch(fetchSchoolList(zipcode)).then(() => {
                    this.validate();
                    this.setState({ fetchingSchoolList: 0 });
                });
            }, 250),
        });
    }

    updateFederalState(zipcode) {
        if (zipcode != null && zipcode.length === 5) {
            this.props.dispatch(fetchZipcodeMapping(zipcode));
        }
    }

    updateIsInLowerSaxony(_zipcode) {
        const isInLowerSaxony = this.props.federalState === 'Niedersachsen';
        this.props.change('isInLowerSaxony', isInLowerSaxony);
        return isInLowerSaxony;
    }

    onAnmeldungSelect(id) {
        console.log(`onAnmeldungSelect: ${id}`);

        const allAnmeldecode = document.getElementsByClassName('anmeldecode');
        if (document.getElementById(`anmeldecode-${id}`)) {
            const currentAnmelde = document.getElementById(`anmeldecode-${id}`);

            const alreadyShown = currentAnmelde.classList.contains('show');

            for (let i = 0; i < allAnmeldecode.length; i++) {
                allAnmeldecode[i].classList.remove('show');
            }
            if (!alreadyShown) {
                currentAnmelde.classList.add('show');
            }
        }
    }

    onAnmeldungEdit(id, index, e) {
        console.log(`onAnmeldungEdit: id: ${id} index: ${index}`);

        const anmeldeInput = document.getElementById(`anmeldecode_input-${id}`);

        const anmeldecode = (anmeldeInput.value || '')?.trim?.();

        console.log('anmeldecode ', anmeldecode);

        console.log(
            'buchungNr ',
            this.props.groupRegistrationList[index].buchungNr,
        );

        if (anmeldecode === this.props.groupRegistrationList[index].buchungNr) {
            this.props.dispatch(setEditedAnmeldung(id, index, anmeldecode));
        } else {
            e.preventDefault();
            showAlert(ERROR, e?._error || ERROR_ANMELDECODE);
        }
    }

    mapIdToSchoolType(schoolTypeId) {
        const schoolType = this.props.schoolTypeList.find(
            (schoolTypeListItem) => schoolTypeListItem.id === schoolTypeId,
        );
        return schoolType ? schoolType.name : '';
    }

    renderQuestion() {
        if (!this.state.ready) {
            return <Loader visible={true} />;
        }

        // Liste liefert im Moment manche Registrierungen mehrfach. Array dient zum Ausfiltern
        const seenBuchungsnr = [];

        const schoolSelectionFormValues =
            this.props.formdata.school_selection.values;
        const schoolRegistrationLink = (
            <div>
                <p>
                    Ihre Schule ist nicht in unserer Datenbank? Schreiben Sie
                    uns direkt <Link to='school-registration'>hier</Link>.
                </p>
            </div>
        );

        let err;
        if (
            this.props.schoolList.length === 0 &&
            schoolSelectionFormValues.zipcode
        ) {
            if (schoolSelectionFormValues.schoolNumber === '') {
                if (!schoolSelectionFormValues.isInLowerSaxony) {
                    err = (
                        <div>
                            <p>
                                Keine Schule im PLZ-Gebiet „
                                {schoolSelectionFormValues.zipcode}“ gefunden!
                            </p>
                            {schoolRegistrationLink}
                        </div>
                    );
                }
            } else if (
                schoolSelectionFormValues.isInLowerSaxony &&
                schoolSelectionFormValues.schoolNumber
            ) {
                err = (
                    <div>
                        <p>
                            Keine Schule mit der Schulnummer „
                            {schoolSelectionFormValues.schoolNumber}“ im
                            PLZ-Gebiet „
                            {schoolSelectionFormValues.zipcode}“ gefunden!
                        </p>
                        {schoolRegistrationLink}
                    </div>
                );
            } else if (this.props.plzMapping.Bundesland === '') {
                err = (
                    <div>
                        <p>
                            Keine Schule im PLZ-Gebiet „
                            {schoolSelectionFormValues.zipcode}“ gefunden!
                        </p>
                        {schoolRegistrationLink}
                    </div>
                );
            }
        }

        return (
            <div className={commonstyle.question}>
                <div className={commonstyle.header}>
                    <h1>
                        <b>Mach doch einfach!</b>
                    </h1>
                    <p>
                        <strong>
                            Wählen Sie bitte Ihre Schule aus und klicken Sie
                            danach auf „Weiter“.
                        </strong>
                        <br />
                        Falls Sie die Angaben einer angemeldete Gruppe ändern
                        möchten, klicken Sie bitte auf die jeweilige Gruppe und
                        geben Sie die Buchungsnummer ein, die Sie bei der
                        Anmeldung per E-Mail erhalten haben.
                    </p>
                </div>
                <fieldset>
                    <label htmlFor='zipcode'>Postleitzahl</label>
                    <Field
                        component='input'
                        type='text'
                        name='zipcode'
                        maxLength='5'
                        className={classNames({
                            invalid: !this.state.validZipcode,
                        })}
                        validate={this.validateZipcode}
                        onChange={(e) => this.updateZipcode(e.target.value)}
                    />
                    <label htmlFor='federalState'>Bundesland</label>
                    <Field
                        component='input'
                        type='text'
                        name='federalState'
                        placeholder={schoolSelectionFormValues.federalState}
                        readOnly={true}
                    />
                    {schoolSelectionFormValues.isInLowerSaxony && (
                        <div>
                            <label htmlFor='schoolNumber'>Schulnummer</label>
                            <Field
                                component='input'
                                type='text'
                                name='schoolNumber'
                                onChange={(e) =>
                                    this.updateSchoolNumber(e.target.value)
                                }
                            />
                        </div>
                    )}
                    <div className={style.schoolTableWrap}>
                        {this.state.fetchingSchoolList ? (
                            <Loader visible={true} />
                        ) : (
                            <></>
                        )}
                        {!this.state.fetchingSchoolList &&
                            this.props.schoolList &&
                            this.props.schoolList.length > 0 && (
                                <>
                                    <ul className={style.schoolTable}>
                                        <div
                                            key='headerSchool'
                                            className={style.header}
                                        >
                                            Schule
                                        </div>
                                        <div
                                            key='headerSchoolform'
                                            className={style.header}
                                        >
                                            Schulform
                                        </div>
                                        <div
                                            key='headerPlace'
                                            className={style.header}
                                        >
                                            Ort
                                        </div>
                                        <div
                                            key='headerAddress'
                                            className={style.header}
                                        >
                                            Adresse
                                        </div>
                                        <div
                                            key='headerSelected'
                                            className={style.header}
                                        />
                                        <ul key='table'>
                                            {this.props.schoolList.map(
                                                (school, index) => {
                                                    if (school.idStatus === 1) {
                                                        return null;
                                                    }

                                                    return (
                                                        <React.Fragment
                                                            key={school.ID}
                                                        >
                                                            <Field
                                                                component='input'
                                                                type='radio'
                                                                name='school_selection'
                                                                onChange={(
                                                                    _e,
                                                                ) =>
                                                                    this.validate()
                                                                }
                                                                value={
                                                                    `${school.ID}` /* TODO: use unique school primary key */
                                                                }
                                                                className={
                                                                    style.field
                                                                }
                                                                id={`school_selection_${index}`}
                                                                onClick={() => {
                                                                    this.props.dispatch(
                                                                        destroy(
                                                                            'registration_personals',
                                                                        ),
                                                                    );
                                                                    this.props.dispatch(
                                                                        destroy(
                                                                            'group',
                                                                        ),
                                                                    );
                                                                    this.props.dispatch(
                                                                        destroy(
                                                                            'schedule',
                                                                        ),
                                                                    );
                                                                    this.props.dispatch(
                                                                        destroy(
                                                                            'allowance',
                                                                        ),
                                                                    );
                                                                    this.props.dispatch(
                                                                        destroy(
                                                                            'validate',
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                            <li
                                                                className={
                                                                    style.schoolTable
                                                                }
                                                            >
                                                                <label
                                                                    htmlFor={`school_selection_${index}`}
                                                                >
                                                                    {
                                                                        school.name
                                                                    }
                                                                </label>
                                                                <label
                                                                    htmlFor={`school_selection_${index}`}
                                                                >
                                                                    {this.mapIdToSchoolType(
                                                                        school.type,
                                                                    )}
                                                                </label>
                                                                <label
                                                                    htmlFor={`school_selection_${index}`}
                                                                >
                                                                    {
                                                                        school.city
                                                                    }
                                                                </label>
                                                                <label
                                                                    htmlFor={`school_selection_${index}`}
                                                                >
                                                                    {
                                                                        school.address
                                                                    }
                                                                </label>
                                                                <label
                                                                    htmlFor={`school_selection_${index}`}
                                                                />
                                                            </li>
                                                        </React.Fragment>
                                                    );
                                                },
                                            )}
                                        </ul>
                                    </ul>

                                    {schoolRegistrationLink}

                                    {this.state.fetchingRegistrationList ? (
                                        <Loader visible={true} />
                                    ) : (
                                        <></>
                                    )}
                                    {!this.state.fetchingRegistrationList &&
                                    this.props.groupRegistrationList &&
                                    this.props.groupRegistrationList.length >
                                        0 ? (
                                        <>
                                            <strong>
                                                Für diese Schule vorhandene
                                                Anmeldungen:
                                            </strong>
                                            <ul className={style.schoolTable}>
                                                <div
                                                    key='headerSchool'
                                                    className={style.header}
                                                >
                                                    Gruppenbezeichnung
                                                </div>
                                                <div
                                                    key='headerSchoolform'
                                                    className={style.header}
                                                >
                                                    Anreisetag
                                                </div>
                                                <div
                                                    key='headerAddress'
                                                    className={style.header}
                                                >
                                                    Anreisezeitpunkt
                                                </div>
                                                <div
                                                    key='headerPlace'
                                                    className={style.header}
                                                >
                                                    Anzahl Schüler
                                                </div>
                                                <div
                                                    key='headerSelected'
                                                    className={style.header}
                                                >
                                                    Angemeldet am
                                                </div>
                                                <ul key='table'>
                                                    {this.props.groupRegistrationList.map(
                                                        (group, index) => {
                                                            // abgesagte Teilnehmerregistrierungen nicht anzeigen
                                                            if (
                                                                group.idStatusWorkflow ===
                                                                WORKFLOW__STATUS
                                                                    .TEILNAHME_ABGESAGT
                                                                    .value
                                                            ) {
                                                                return null;
                                                            }

                                                            // Teilnehmerregistrierungen mit stornierten Workshop-Buchungen nicht anzeigen
                                                            if (
                                                                group.idStatusWorkshop ===
                                                                WORKSHOP__STATUS
                                                                    .STORNIERT
                                                                    .value
                                                            ) {
                                                                return null;
                                                            }

                                                            // Bereits einmal ausgegebene Teinhmerregistrierungen ueberspringen
                                                            if (
                                                                seenBuchungsnr.includes(
                                                                    group.buchungNr,
                                                                )
                                                            ) {
                                                                return null;
                                                            }

                                                            // Diese Registrierung als bereits angezeigt merken
                                                            seenBuchungsnr.push(
                                                                group.buchungNr,
                                                            );

                                                            return (
                                                                <React.Fragment
                                                                    key={
                                                                        group.ID
                                                                    }
                                                                >
                                                                    <li
                                                                        className={
                                                                            style.schoolTable
                                                                        }
                                                                        onClick={() =>
                                                                            this.onAnmeldungSelect(
                                                                                group.ID,
                                                                            )
                                                                        }
                                                                    >
                                                                        <label>
                                                                            {' '}
                                                                            {
                                                                                group.gruppeBezeichnung
                                                                            }
                                                                        </label>
                                                                        <label>
                                                                            {' '}
                                                                            {dayjs(
                                                                                group.anreisetag,
                                                                            ).format(
                                                                                'DD.MM.YYYY',
                                                                            ) ||
                                                                                '-'}
                                                                        </label>
                                                                        <label>
                                                                            {' '}
                                                                            {(
                                                                                this
                                                                                    .props
                                                                                    .arrivalTimeList ||
                                                                                []
                                                                            ).find(
                                                                                (
                                                                                    time,
                                                                                ) =>
                                                                                    time.ID ===
                                                                                    group.idAnkunftszeit,
                                                                            )
                                                                                ?.description ||
                                                                                '-'}
                                                                        </label>
                                                                        <label>
                                                                            {' '}
                                                                            {(parseInt(
                                                                                group.anzahlJungen,
                                                                                10,
                                                                            ) ||
                                                                                0) +
                                                                                (parseInt(
                                                                                    group.anzahlMaedchen,
                                                                                    10,
                                                                                ) ||
                                                                                    0)}
                                                                        </label>
                                                                        <label>
                                                                            {' '}
                                                                            {dayjs(
                                                                                group.TS_NEW,
                                                                            ).format(
                                                                                'DD.MM.YYYY',
                                                                            )}
                                                                        </label>
                                                                    </li>
                                                                    <li
                                                                        className='anmeldecode'
                                                                        id={`anmeldecode-${group.ID}`}
                                                                    >
                                                                        <span>
                                                                            Buchungsnummer:
                                                                        </span>
                                                                        <input
                                                                            id={`anmeldecode_input-${group.ID}`}
                                                                            type='text'
                                                                            name='anmeldecode'
                                                                        />
                                                                        <Link
                                                                            className='button'
                                                                            to='/personals'
                                                                            onClick={(
                                                                                e,
                                                                            ) => {
                                                                                this.onAnmeldungEdit(
                                                                                    group.ID,
                                                                                    index,
                                                                                    e,
                                                                                );
                                                                                document
                                                                                    .querySelector(
                                                                                        '.simplebar-content-wrapper',
                                                                                    )
                                                                                    .scrollTo(
                                                                                        0,
                                                                                        0,
                                                                                    );
                                                                            }}
                                                                        >
                                                                            Bearbeiten
                                                                        </Link>
                                                                    </li>
                                                                </React.Fragment>
                                                            );
                                                        },
                                                    )}
                                                </ul>
                                            </ul>
                                        </>
                                    ) : (
                                        !this.state
                                            .fetchingRegistrationList && (
                                            <>
                                                <strong>
                                                    Für die ausgewählte Schule
                                                    sind keine Anmeldungen
                                                    vorhanden.
                                                </strong>
                                            </>
                                        )
                                    )}
                                </>
                            )}
                    </div>
                    {err}
                </fieldset>
                <StepButtons
                    back='/welcome'
                    to='/personals'
                    disabled={!this.state.valid}
                />
            </div>
        );
    }
}

const mapStateToProps = (s) => {
    return { ...s, formdata: s.form };
};

export default reduxForm({
    form: 'school_selection',
    destroyOnUnmount: false,
})(connect(mapStateToProps, null)(SchoolSelection));
